import { LiteralUnion } from 'type-fest';

type Suggested<T extends string> = LiteralUnion<T, string>;

/**
 * Environment configuration object.
 *
 * When adding a variable, make sure they are are also defined in
 * - all files under `<root>/config`
 * - `src/config/__mocks__/env.ts`
 */
export const env = {
  SEEEN_APP_ENV: 'stg' as Suggested<'local' | 'stg' | 'prd'>,
  SEEEN_APP_BUILD_VERSION: '$SEEEN_APP_BUILD_VERSION' as string,
  SEEEN_APP_BACKEND_URL: 'https://stg.api.cs.seeen.com/' as string,
  SEEEN_APP_GOOGLE_CLIENT_ID: '784380735864-atonnl55tv9p47k9gik5e98fmmnd4qgv.apps.googleusercontent.com' as string,
  SEEEN_APP_GOOGLE_ANALYTICS_ID: '' as string,
  SEEEN_APP_SENTRY_DSN: 'https://7cd5236d7b9e400ba89371c62419a036@sentry.10clouds.com/540' as string,
  SEEEN_APP_SENTRY_ENV: 'stg' as string,
  SEEEN_APP_STRIPE_PUBLISHABLE_KEY: 'pk_test_51Hrm7fKnAKB8grLchfPXl6o64PSAmHWYgaH7A5nutcDvFgd7g335KHkhqmaWMIkhifnYCI6zzDKmZixs1qbqKfag00P3cymLCY' as string,
  SEEEN_APP_INTERCOM_ID: '' as string,
  SEEEN_APP_PLAYER_WIDGET_URL: 'https://stg.code.seeen.com/carousel-widget/widget/latest/player-widget.umd.min.js' as string,
  SEEEN_APP_CAROUSEL_WIDGET_URL: 'https://stg.code.seeen.com/carousel-widget/widget/latest/carousel-widget.umd.min.js' as string,
  SEEEN_APP_VERTICAL_CAROUSEL_WIDGET_URL: 'https://stg.code.seeen.com/carousel-widget/widget/latest/vertical-carousel-widget.umd.min.js' as string,
};

// Perform runtime check for successful variable injection.
// String constructor invokation is needed to work around the terser minification at compilation time.
if (String(env.SEEEN_APP_ENV) === '%SEEEN_APP_ENV' + String('%')) {
  throw new Error('SEEEN_APP_ENV needs to be injected before running the application');
}
